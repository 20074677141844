import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"



const ProcessLogin = () => {
  const location = useLocation();

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
        <div className="row mt-1">
          <div className="col p-3">
            Your login processed successfully. Please start a new session.
          </div>
          <br />
            <Link to={'/messages'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Start a Session</Button>
            </Link>
        </div>
      </div>
    </div>
  );
};

export default ProcessLogin;
