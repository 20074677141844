import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom"


const ThemeSelection = ({ handleThemeChange }) => {
    const [themes, setThemes] = useState([]);
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const fetchThemes = async () => {
        try {
            const response = await axios.get('/api/allthemes');
            const tempThemes = response.data.themes;
            const parsedThemes = tempThemes?.map(tempTheme => JSON.parse(tempTheme)) || [];
            console.log(parsedThemes);
            setThemes(parsedThemes);
        } catch (error) {
            console.error('Error fetching themes', error);
        }
    };

    useEffect(() => {
        fetchThemes();
    }, []);

    return (
        <div className="p-1">
            <div className="container px-1 mt-1 justify-content-md-center psyd-primary-bg-color button-block text-center">
                <Button id="btn-save" onClick={() => handleThemeChange(themes[index].theme_name)}>Save</Button>
            </div>
            <div className="container px-1 mt-3 psyd-primary-bg-color button-block">
                <Carousel interval={null} activeIndex={index} onSelect={handleSelect}>
                    {themes?.map((theme, index) => (
                        <Carousel.Item key={index} className="px-3 mt-3">
                            <div class="row align-self-end">
                                <div class="col-sm-12">
                                    <img className="mx-auto d-block w-100" src={`../img/${theme.thumbnail_name}`} alt={theme.theme_name}/>
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default ThemeSelection;
