// Header.js
import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

const Header = () => (
    <div className="container text-center psyd-primary-bg-color psyd-font-light" >
        <div id='homediv' className="container px-0">
            <div className="container px-0 psyd-primary-bg-color psyd-font shadow-lg rounded">
                <div className="row mt-0 px-0">
                    <div className="col-sm-2  d-none d-md-block d-lx-block align-left justify-content-start">
                    <img className="align-left" src="../img/logo-128.jpg" alt="psyd chat" />
                    </div>
                    <div className="col-sm-8  psyd-font-light">
                        <h3>Personalized Therapy</h3>
                        <h6>Anywhere, Anytime</h6>
                    </div>
                    <div className="col-sm-2">
                        <Link to={'/messages'}>
                            <Button>Start a Session</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Header
