import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../css/navbar-main.css'
import { Link } from "react-router-dom"

const NavbarMain = () => {
    return (
        <Navbar expand="lg" sticky="top" className="px-0">
            <Container>
                <Navbar.Brand href="/">Psyd</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto navbar-right">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/plans">Plans</Nav.Link>
                        <Nav.Link href="/sessions">Sessions</Nav.Link>
                        <Nav.Link href="/theme-select">Themes</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/login">Login</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarMain
