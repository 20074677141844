import { Link } from "react-router-dom";
import '../css/footer.css'

const Footer = () => {

    return (
        <div>
            <hr/>
            <div class="container text-center">
                <div class="row">
                    <div class="col-md-4">
                    <Link to={'http://localhost:3000/copyright'}>Copyright Info</Link>
                    </div>
                    <div class="col-md-4">
                    <Link to={'http://mlai.co'}>MLAI Solutions LLC</Link>
                    </div>
                    <div class="col-md-4">
                    <Link to={'http://mlai.co'}>Terms and Conditions</Link>                        
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Footer;