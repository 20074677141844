// src/App.js
import React, { useState, useEffect } from 'react';
import './css/app.css';
import { ThemeProvider } from 'styled-components';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Messages from './components/chat';
import Home from './components/Home';
import NavbarMain from './components/NavbarMain';
import campfireTheme from './components/CampfireTheme';
import luxuryTheme from './components/LuxuryTheme';
import starryTheme from './components/StarryTheme';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Copyright from './components/Copyright';
import ThemeSelection from './components/ThemeSelection';
import About from './components/About';
import Plans from './components/plans';
import Layout from './components/Layout';
import PricingPage from './components/pricing';
import ProcessSubscription from './components/processsubscription';
import Sessions from './components/sessions';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import ProcessLogin from './components/processLogin';

const themes = [campfireTheme, luxuryTheme, starryTheme]

function App() {
  const [currentTheme, setCurrentTheme] = useState(campfireTheme)      
  const [theme, setTheme] = useState('starryTheme');
  const handleThemeChange = (newTheme) => {
      setCurrentTheme(newTheme);
      setTheme(newTheme);
      Cookie.set('theme', newTheme);  // Store sessionid in cookies

  }
  useEffect(() => {
    const selectedTheme = Cookies.get("theme");
    if (selectedTheme === 'default') {import('./css/psyd-default.css')} 
    else if (selectedTheme === 'campfireTheme') {import('./css/psyd-campfire.css')} 
    else if (selectedTheme === 'luxuryTheme') {import('./css/psyd-luxury.css')}
    else if (selectedTheme === 'starryTheme') {import('./css/psyd-starry.css')}
    else if (selectedTheme === 'blackTheme') {import('./css/psyd-black.css')}
    else if (selectedTheme === 'whiteTheme') {import('./css/psyd-white.css')}

  }, [theme]);

  return (

      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
            <Route index path='/' element={<Home />} />
            <Route index path='/messages' element={<Messages />} />
            <Route path='/sessions' element={<Sessions />} />
            <Route path='/login' element={<Login />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/theme-select' element={<ThemeSelection handleThemeChange={handleThemeChange}/>}/>
            <Route path='/copyright' element={<Copyright/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/plans' element={<Plans/>}/>
            <Route path='/purchase' element={<PricingPage/>}/>
            <Route path='/processsubscription' element={<ProcessSubscription/>}/>
            <Route path='/processlogin' element={<ProcessLogin/>}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;


      {/* <header className="App-header">
        <h1>Welcome to a Virtual Therapist using Generative AI.</h1>
        <h3>Please enter your concerns, hit enter and I will provide a response for you.</h3>
        </header> */}

       