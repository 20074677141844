import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import ListGroup from 'react-bootstrap/ListGroup';
import '../css/home.css'

const Home = () => {

    return (

        <div id='homediv' className="container px-0">
            <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                <div className="row mt-1 mb-4">
                    <div className="col p-3">
                        This platform, PSYD, powered by advanced artificial intelligence and language models, offers personalized online therapy sessions. Whether you're dealing with stress, anxiety, or other mental health challenges, this AI-powered therapist can provide guidance, coping strategies, and a safe space to explore your thoughts and feelings.
                    </div>
                </div>
            </div>
            <div className="container px-0 psyd-font shadow-lg rounded">
                <div class="row mt-2">
                    <div class="col-md-6">
                        <div className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">
                            <ListGroup className="shadow-lg rounded p-1">
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">24/7 Availability: Round-the-clock access to mental health support.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Personalized Therapy: Tailored responses and resources based on individual needs.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Privacy and Anonymity: Anonymous engagement for reduced stigma.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Flexible Session Options: Offers various session lengths to accommodate different support needs.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Automated Progress Tracking: Tracks user interactions and progress over time.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Therapy Session Summaries: Generates summaries of sessions for user review.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Goal Setting and Monitoring: Helps users set and track therapeutic goals.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Integration with Human Therapists: Facilitates seamless handoffs to human therapists.</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div className="text-center psyd-secondary-bg-color psyd-font shadow-lg rounded d-none d-md-block d-xl-block">
                            <img
                                className="" src="../img/OnlineTherapy.jpg"
                                alt="psyd chat"
                                style={{ width: '600px', height: '350px' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Link to={'/messages'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Start a Session</Button>
            </Link>
        </div>
    )
}

export default Home


