const About = () => {
    return (
        <div class="container">
            <div class="psyd-primary-bg-color psyd-font-light shadow-lg rounded p-1 mt-2">
                <h2>Our Mission</h2>
            </div>
            <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                <div className="row mt-1 mb-4">
                    <div className="col p-3">
                    At PSYD, we believe that mental health support should be accessible to everyone, anytime, anywhere. Our mission is to revolutionize the way individuals seek help by harnessing the power of Generative AI to create a user-friendly online therapy platform. We are committed to breaking down barriers to mental wellness, offering personalized, empathetic, and immediate support to those in need. Through innovative technology and a focus on inclusivity, we strive to empower individuals on their journey to better mental health, fostering a world where seeking help is as easy as a click away.                    
                    </div>
                </div>
            </div>
            <div class="psyd-primary-bg-color psyd-font-light shadow-lg rounded p-1 mt-2">
                <h2>Our Approach</h2>
            </div>
            <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                <div className="row mt-1 mb-4">
                    <div className="col p-3">
                        Our technical approach at PSYD leverages cutting-edge Generative AI algorithms to deliver personalized therapy experiences tailored to each user’s unique needs. By combining natural language processing with advanced machine learning, our platform analyzes user interactions to provide real-time, context-aware support. This allows us to create dynamic therapeutic content, including guided sessions, coping strategies, and interactive exercises, all accessible through a seamless interface. We prioritize data security and privacy, ensuring that all user information is protected while providing an intuitive experience that promotes engagement and comfort. Through continuous feedback and adaptation, we aim to refine our algorithms, making mental health care not only accessible but also effective and responsive
                   </div>
                </div>
            </div>
            <div class="psyd-primary-bg-color psyd-font-light shadow-lg rounded p-1 mt-2">
                <h2>Meet Our Expert Team</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="">
                        <div class="text-center">
                            <img src="./img/DrSarikaGarga2.png" alt="Image" width="140" />
                        </div>
                        <div class="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mt-2">
                            <h3>Sarika Garga, Ph.D.</h3>
                            <span>Chief Excutive Officer</span>
                            <div class="mt-2">
                                <p>Dr. Sarika Garga has completed her Doctorate in Psychology (Ph.D.) with a
                                    Master's degree in Psychology (MA), and a Masters in Philosophy (M.Phil).She is
                                    credentialed as a "Licensed Clinical Psychologist" in VA and as "Licensed
                                    Psychologist" in Washington DC.
                                </p>
                                <p>
                                    Dr. Garga has extensive experience in conducting Psycho-educational Testing,
                                    Developmental Evaluations,
                                    Cognitive Abilities Testing, and Testing for Learning Disabilities. She is applying
                                    her expertise in understanding of how humans learn and act
                                    to developing Artifical Intelligence and Machine Learning models.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="">
                        <div class="text-center">
                            <img src="./img//ShreyasGarga.png" alt="Image" width="140" />
                        </div>
                        <div class="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mt-2">
                            <h3>Shreyas Garga</h3>
                            <span>Chief Technology Officer</span>
                            <div class="mt-2">
                                <p>Shreyas is a driven data scientist with a Bachelor’s in Neuroscience and experience
                                    in business and social analytics. He has created numerous automated and responsive data
                                    analytics applications and solutions to identify, analyze, and interpret trends and patterns.</p>
                                <p>
                                    Shreyas is working on combining his knowledge of Neuro-Science and Computer Science
                                    with research and analytical skills to develop libraries and models that can work as foundational frameworks for
                                    Artifical Intelligence and Machine Leaning based solutions.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;