// src/therapy.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Sessions() {
    const [messages, setMessages] = useState([]);
    const navigate = useNavigate();
    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/usersessions');
            //const sessionid = response.data.sessionid;
            //Cookie.set('sessionid', sessionid.sessionid);  // Store sessionid in cookies
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const handleSendInput = async (session_id) => {
        try {
            if(session_id){        
            Cookies.remove('sessionid');
            Cookie.set('sessionid', session_id);
            navigate('/messages');
            }
            else{
                Cookies.remove('sessionid');
                navigate('/messages');
            }
        } catch (error) {
            console.error('Error sending input', error);
        }
    };

    useEffect(() => {
        const userCookieExists = Cookies.get('userid') !== undefined;
        if(userCookieExists){
            fetchMessages();
        }
        else{
            const sesssionCookieExists = Cookies.get('sessionid') !== undefined;
            if (sesssionCookieExists) {
                fetchMessages();
            }    
        }
    }, []);

    return (
        <div>
            <div class="container mt-4">
                <div class="row">
                    <div class="col">
                        <div className="px-2 border border-3 rounded-top psyd-primary-bg-color psyd-font-light">
                            On this page, you'll find a list of your three most recent sessions, providing you with an easy reference to your past discussions. You can choose to continue one of these sessions to delve deeper into the topics you’ve explored, or you can opt to start a new session if you wish to address different concerns. This streamlined approach ensures that you can easily navigate your therapeutic journey and receive the support you need, tailored specifically to your evolving needs.
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <div className="px-2 psyd-primary-bg-color psyd-font-light">
                            <ul className="mx-0 px-0">
                                {messages.length === 0 && <p className="text-center text-muted">No messages yet</p>}
                                <div className="row px-1">
                                    <div className="col-sm-3 mb-2 mt-2 p-2 psyd-secondary-bg-color psyd-font">
                                        Session Date
                                    </div>
                                    <div className="col-sm-3 mb-2 mt-2 p-2 psyd-secondary-bg-color psyd-font">
                                        Input
                                    </div>
                                    <div className="col-sm-3 mb-2 mt-2 p-2 psyd-secondary-bg-color psyd-font">
                                        Response
                                    </div>
                                    <div className="col-sm-3 mb-2 mt-2 p-2 psyd-secondary-bg-color psyd-font">
                                        &nbsp;
                                    </div>
                                </div>
                                {messages?.map(record => (
                                    <li key={record.history_id} className="psyd-chat">
                                        <div className="row px-1">
                                            <div className="col-sm-3 mb-2 mt-2 p-2 psyd-primary-bg-color psyd-font-light">
                                                {record.created_timestamp}
                                            </div>
                                            <div className="col-sm-3 mb-2 mt-2 p-2 psyd-primary-bg-color psyd-font-light">
                                                {record.input_text}
                                            </div>
                                            <div className="col-sm-3 mb-2 mt-2 p-2 psyd-primary-bg-color psyd-font-light">
                                                {record.response_text}
                                            </div>
                                            <div className="col-sm-3 mb-2 mt-2 p-2 psyd-primary-bg-color psyd-font-light">
                                                <Link to={'/messages'} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Button onClick={() => handleSendInput(record.session_id)}>Continue this Session</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <Form className="mt-3">
                        <Form.Group className="d-flex">
                            <Button variant="primary" onClick={() => handleSendInput()}className="ms-2">Start a new session</Button>
                        </Form.Group>
                    </Form>
                </div>

            </div>
        </div>
    );
}

export default Sessions;
