// src/therapy.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Messages() {
    const [messages, setMessages] = useState([]);
    const [newClientInput, setNewClientInput] = useState('');

    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/therapy');
            //const sessionid = response.data.sessionid;
            //Cookie.set('sessionid', sessionid.sessionid);  // Store sessionid in cookies
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const handleSendInput = async () => {
        if (newClientInput.trim() === '') return;
        try {
            const res = await axios.post('/api/therapy', { content: JSON.stringify({ "clientinput": newClientInput }) });
            const sessionid = res.data.sessionid;
            console.error("Session ID:", sessionid);
            Cookie.set('sessionid', sessionid);  // Store sessionid in cookies
            const userid = res.data.userid;
            //Cookie.set('userid', res.data.userid);  // Store sessionid in cookies
            setNewClientInput('');
            fetchMessages();
        } catch (error) {
            console.error('Error sending input', error);
        }
    };

    useEffect(() => {
        const cookieExists = Cookies.get('sessionid') !== undefined;
        if (cookieExists) {
            fetchMessages();
        }
    }, []);

    return (
        <div>
            <div class="container mt-4">
                <div class="row justify-content-md-center gy-2">
                    <div class="col-md-6 px-0 psyd-secondary-bg-color order-2">
                        <div className="px-0 psyd-font rounded">
                            <div className="px-0 border border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                                Tips for Therapy
                            </div>
                            <div className="px-1 border border-top-0 border-3 rounded-top rounded-left rounded-right ">
                            <ListGroup className="shadow-lg rounded p-1">
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Be Open and Honest: Treat the AI therapist as you would a human therapist. Share your thoughts, feelings, and experiences openly and honestly to get the most out of the sessions.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Set Clear Goals: Before starting therapy, identify specific goals you want to achieve. This will help the AI tailor its responses and provide more focused support.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Regularly Engage: Consistency is key. Schedule regular sessions and stick to the schedule to build a strong therapeutic relationship with the AI.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Experiment with Different Approaches: If you feel like the AI's responses aren't helpful, try rephrasing your questions or exploring different topics.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Supplement with Human Therapy: While AI can be a valuable tool, it's important to remember that it cannot replace human connection. Consider consulting with a human therapist for complex issues or when you need personal interaction.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Be Patient: AI is still developing, and it may take time for the AI to fully understand and respond to your needs. Be patient and give the technology a chance to learn and improve.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Combine with Self-Care: While AI therapy can be beneficial, it's essential to combine it with other self-care practices like exercise, meditation, and healthy eating.</ListGroup.Item>
                                <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1">Remember, It's a Tool: AI therapy is a tool to assist you, not a replacement for professional help. Use it as a supplement to your overall mental health journey.</ListGroup.Item>
                            </ListGroup>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 order-1">
                        <div className="px-0 border border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Your therapist Psyd
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right" style={{ height: '400px', overflowY: 'scroll' }}>
                            <ul class="mx-0 px-0 ">
                                {messages.length === 0 && <p className="text-center text-muted">No messages yet</p>}
                                {messages?.map(record => (
                                    <li class="psyd-chat">
                                        <div key={record.history_id}> {/* Reduced margin for better spacing */}
                                            <div className="d-flex justify-content-end mb-2 mt-2"> {/* Small margin for input_text */}
                                                <div className="p-2  rounded psyd-chat-input" style={{ maxWidth: '70%' }}>
                                                    {record.input_text}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-start"> {/* No margin needed here */}
                                                <div className="p-2 rounded psyd-chat-response" style={{ maxWidth: '70%' }}>
                                                    {record.response_text}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <Form className="mt-3">
                            <Form.Group className="d-flex">
                                <Form.Control
                                    type="text"
                                    value={newClientInput}
                                    onChange={(e) => setNewClientInput(e.target.value)}
                                    placeholder="Type your message..."
                                />
                                <Button variant="primary" onClick={handleSendInput} className="ms-2">Send</Button>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messages;
