import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// Set axios default settings to include cookies
axios.defaults.withCredentials = true;


function Plans() {
    const [userId, setUserID] = useState('');
    const [guestPlans, setGuestPlans] = useState([]);
    const [guestPlansPricing, setGuestPlansPricing] = useState([]);

    const [freePlans, setFreePlans] = useState([]);
    const [freePlansPricing, setFreePlansPricing] = useState([]);

    const [basicPlans, setBasicPlans] = useState([]);
    const [basicPlansPricing, setBasicPlansPricing] = useState([]);

    const [premiumPlans, setPremiumPlans] = useState([]);
    const [premiumPlansPricing, setPremiumPlansPricing] = useState([]);

    const [ultimatePlans, setUltimatePlans] = useState([]);
    const [ultimatePlansPricing, setUltimatePlansPricing] = useState([]);

    const [familyPlans, setFamilyPlans] = useState([]);
    const [famlyPlansPricing, setFamilyPlansPricing] = useState([]);


    const fetchPlans = async () => {
        try {

            let userid_cookie = Cookies.get('userid')
            if (userid_cookie) { setUserID(userid_cookie) }

            const response = await axios.get('/api/allplans');
            const tempplans = response.data.plans;
            const parsedPlans = tempplans?.map(tempplan => JSON.parse(tempplan)) || [];
            // Filter for plans with subscription_name 'Free Plan'
            const guestPlans = parsedPlans.filter(plan => plan.subscription_name === 'Guest Plan');
            setGuestPlans(guestPlans);
            setGuestPlansPricing(guestPlans[0]);

            const freePlans = parsedPlans.filter(plan => plan.subscription_name === 'Free Plan');
            setFreePlans(freePlans);
            setFreePlansPricing(freePlans[0]);

            const basicPlans = parsedPlans.filter(plan => plan.subscription_name === 'Basic Plan');
            setBasicPlans(basicPlans);
            setBasicPlansPricing(basicPlans[0]);
            console.error(basicPlans);

            const premiumPlans = parsedPlans.filter(plan => plan.subscription_name === 'Premium Plan');
            setPremiumPlans(premiumPlans);
            setPremiumPlansPricing(premiumPlans[0]);

            const ultimatePlans = parsedPlans.filter(plan => plan.subscription_name === 'Ultimate Plan');
            setUltimatePlans(ultimatePlans);
            setUltimatePlansPricing(ultimatePlans[0]);

            const familyPlans = parsedPlans.filter(plan => plan.subscription_name === 'Family Plan');
            setFamilyPlans(familyPlans);
            setFamilyPlansPricing(familyPlans[0]);


        } catch (error) {
            console.error('Error fetching plans', error);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, []);
    return (
        <div>
            <div class="container mt-4">
                <div class="row justify-content-md-center gy-2">
                    <div class="col-md-4">
                        <div className="px-0 border border-3 rounded-top psyd-secondary-bg-color psyd-font-dark text-center">
                            Guest Plan
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Monthly Pricing: {guestPlansPricing.price_monthly}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Annual Pricing: {guestPlansPricing.price_annual}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions per month: {guestPlansPricing.sessions_per_month}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions History Retention Periond: {guestPlansPricing.session_history_retention_period}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul class="mx-0 px-0">
                                {guestPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {guestPlans?.map((guestPlan, index) => (
                                    <li class="psyd-chat">
                                        <div key={guestPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font-light" style={{ width: '90%' }}>
                                                    {guestPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={guestPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="px-0 border border-3 rounded-top psyd-secondary-bg-color psyd-font-dark text-center">
                            Free Plan
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Monthly Pricing: {freePlansPricing.price_monthly}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Annual Pricing: {freePlansPricing.price_annual}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions per month: {freePlansPricing.sessions_per_month}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions History Retention Periond: {freePlansPricing.session_history_retention_period}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul class="mx-0 px-0">
                                {freePlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {freePlans?.map((freePlan, index) => (
                                    <li class="psyd-chat">
                                        <div key={freePlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font-light" style={{ width: '90%' }}>
                                                    {freePlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={freePlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="px-0 border border-3 rounded-top psyd-secondary-bg-color psyd-font-dark text-center">
                            Basic Plan
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Monthly Pricing: {basicPlansPricing.price_monthly}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Annual Pricing: {basicPlansPricing.price_annual}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions per month: {basicPlansPricing.sessions_per_month}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions History Retention Periond: {basicPlansPricing.session_history_retention_period}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul class="mx-0 px-0">
                                {basicPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {basicPlans?.map((basicPlan, index) => (
                                    <li class="psyd-chat">
                                        <div key={basicPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font-light" style={{ width: '90%' }}>
                                                    {basicPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={basicPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mt-4">
                <div class="row justify-content-md-center gy-2">
                    <div class="col-md-4">
                        <div className="px-0 border border-3 rounded-top psyd-secondary-bg-color psyd-font-dark text-center">
                            Premium Plan
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Monthly Pricing: {premiumPlansPricing.price_monthly}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Annual Pricing: {premiumPlansPricing.price_annual}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions per month: {premiumPlansPricing.sessions_per_month}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions History Retention Periond: {premiumPlansPricing.session_history_retention_period}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul class="mx-0 px-0">
                                {premiumPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {premiumPlans?.map((premiumPlan, index) => (
                                    <li class="psyd-chat">
                                        <div key={premiumPlan.id}>
                                            <div className="d-flex px-0 mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font-light" style={{ width: '90%' }}>
                                                    {premiumPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={premiumPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="px-0 border border-3 rounded-top psyd-secondary-bg-color psyd-font-dark text-center">
                            Ultimate Plan
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Monthly Pricing: {ultimatePlansPricing.price_monthly}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Annual Pricing: {ultimatePlansPricing.price_annual}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions per month: {ultimatePlansPricing.sessions_per_month}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions History Retention Periond: {ultimatePlansPricing.session_history_retention_period}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul class="mx-0 px-0">
                                {ultimatePlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {ultimatePlans?.map((ultimatePlan, index) => (
                                    <li class="psyd-chat">
                                        <div key={ultimatePlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font-light" style={{ width: '90%' }}>
                                                    {ultimatePlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={ultimatePlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="px-0 border border-3 rounded-top psyd-secondary-bg-color psyd-font-dark text-center">
                            Family Plan
                        </div>
                        <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Monthly Pricing: {famlyPlansPricing.price_monthly}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Annual Pricing: {famlyPlansPricing.price_annual}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions per month: {famlyPlansPricing.sessions_per_month}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light">Sessions History Retention Periond: {famlyPlansPricing.session_history_retention_period}</div>
                            <div className="px-0 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul class="mx-0 px-0">
                                {familyPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {familyPlans?.map((familyPlan, index) => (
                                    <li class="psyd-chat">
                                        <div key={familyPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font-light" style={{ width: '90%' }}>
                                                    {familyPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={familyPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container px-1 mt-1 justify-content-md-center psyd-primary-bg-color button-block text-center">

                    {userId ? (
                        //<Link to='/purchase'>
                        <Button disabled>Purchase a Plan</Button>
                        //</Link>
                    ) : (
                        <Button disabled>Purchase a Plan</Button> // Disabled button when userId doesn't exist
                    )}
                </div>

            </div>
        </div>
    );
}

export default Plans;
